export enum DialogResponse {
  DASHBOARD = 'DASHBOARD',
  NEW_DISPUTE = 'NEW_DISPUTE',
  SUBMIT = 'SUBMIT',
  GO_BACK = 'GO_BACK',
  DECLINE = 'DECILE',
  APPROVE = 'APPROVE',
  APPROVE_AND_CLOSE = 'APPROVE_AND_CLOSE',
  AMEND = 'AMEND',
  CANCEL = 'CANCEL',
  REASSIGN = 'REASSIGN',
  /**
   * Used for close modal without emit value
   */
  CLOSE = '',
  SKIP = 'SKIP',
  SEND = 'SEND',
  DIALOG_CLOSED = 'DIALOG_CLOSED',
  EXIT_THIS_PAGE = 'EXIT_THIS_PAGE',
  STAY_ON_THIS_PAGE = 'STAY_ON_THIS_PAGE',
  DOWNLOAD_CONFIRM = 'DOWNLOAD_CONFIRM'
}
