
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMetadataApiService } from '@xpo-ltl-2.0/sdk-appmetadata';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { XpoLtlEmailService } from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoBrowserGuardModule, XpoBrowserType, XPO_BROWSER_RESTRICTIONS } from '@xpo-ltl/ngx-ltl-core/browser-guard';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoHeaderModule } from '@xpo-ltl/ngx-ltl-core/header';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoLtlGlobalSearchModule } from '@xpo-ltl/ngx-ltl-global-search';
import { CustomerApiService, SdkCustomerModule } from '@xpo-ltl/sdk-customer';
import { SdkElasticSearchModule } from '@xpo-ltl/sdk-elasticsearch';
import { LocationApiService } from '@xpo-ltl/sdk-location';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';

import { AngularFireModule } from '@angular/fire/compat';
import { SdkHumanResourceModule } from '@xpo-ltl-2.0/sdk-humanresource';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SdkDisputesModule } from '@xpo-ltl/sdk-disputes';
import { CountryPickerModule } from 'ngx-country-picker';

import { AngularSplitModule } from 'angular-split';

import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { CurrencyCdPipe } from '../../lib/src/lib/notes-emails/pipes/currency-cd.pipe';
import { DocumentSearchPipe } from '../../lib/src/lib/notes-emails/pipes/document-search.pipe';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-auth-ui';
import { SdkDocumentManagementModule } from '@xpo-ltl/sdk-documentmanagement';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DisputesDialogModule } from './components/disputes/disputes-dialog.module';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotificationComponent } from './components/notification/notification.component';
import { MaterialModule } from './material.module';
import { HumanizePipe } from './pipes/humanize.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AppConstantsService } from './services/app-constants.service';
import { DisputesDialogService } from './services/disputes/disputes-dialog.service';
import { DecimalPipe } from '@angular/common';
import { SdkCustomerModule as SdkCustomerV2Module } from '@xpo-ltl-2.0/sdk-customer';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('../assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    NotAuthorizedComponent,
    NotificationComponent,
    DocumentSearchPipe,
    CurrencyCdPipe,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ConfigManagerModule,
    XpoHeaderModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    XpoShellModule,
    XpoLtlGlobalSearchModule,
    XpoBrowserGuardModule,
    SdkDisputesModule,
    SdkElasticSearchModule,
    SdkHumanResourceModule,
    SdkLoggingModule,
    SdkUserPreferenceModule,
    CountryPickerModule.forRoot(),
    AngularSplitModule,
    DisputesDialogModule,
    SdkDocumentManagementModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    XpoLtlAuthUiModule,
    AppRoutingModule,
    AngularEditorModule,
    XpoPageNotFoundRoutingModule,
    SdkCustomerV2Module,
    SdkCustomerModule
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: '../assets/config.json' },
    },
    {
      provide: XPO_BROWSER_RESTRICTIONS,
      useValue: {
        blocked: [
          { browser: XpoBrowserType.InternetExplorer },
          { browser: XpoBrowserType.Edge },
          { browser: XpoBrowserType.Opera },
          { browser: XpoBrowserType.Safari },
          { browser: XpoBrowserType.Firefox },
        ],
        suggestions: [{ browser: XpoBrowserType.Chrome }],
      },
    },
    LocationApiService,
    AppConstantsService,
    AppMetadataApiService,
    DataApiService,
    NotificationService,
    XpoLtlEmailService,
    XpoSnackBar,
    ConditioningService,
    FormatValidationService,
    CustomerApiService,
    HumanizePipe,
    DecimalPipe,
    XpoLtlAuthenticationService,
    DisputesDialogService
  ],
  entryComponents: [NotAuthorizedComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
