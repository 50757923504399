import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { Contact, EmailInteraction, Party, ShpmtRecycledPro } from '@xpo-ltl/sdk-disputes';
import { Observable } from 'rxjs';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoSnackBarStatus } from '@xpo-ltl/ngx-ltl-core/snack-bar/models/xpo-snack-bar-status.type';
import { ConfirmNewClaimsDisputeDialogComponent } from '../../components/disputes/components/confirm-new-claims-dispute-dialog';
import { ConfirmNewDisputeDialogComponent } from '../../components/disputes/components/confirm-new-dispute-dialog';
import { ContactsListDialogComponent } from '../../components/disputes/components/contacts-list-dialog/contacts-list-dialog.component';
// tslint:disable-next-line:max-line-length
import { DisputeRouteGuardDialogComponent } from '../../components/disputes/components/dispute-route-guard-dialog/dispute-route-guard-dialog.component';
// tslint:disable-next-line: max-line-length
import { DisputesExportDialogComponent } from '../../components/disputes/components/disputes-export-dialog/disputes-export-dialog.component';
// tslint:disable-next-line: max-line-length
import { DisputesHistoryDialogComponent } from '../../components/disputes/components/disputes-history-dialog/disputes-history-dialog.component';
import {
  ApprobationEmailDialogFormBuilder,
  CancelationEmailDialogFormBuilder,
  DeclinationEmailDialogFormBuilder,
  EmailDialogComponent,
} from '../../components/disputes/components/email-dialog';
import { AmendEmailDialogFormBuilder } from '../../components/disputes/components/email-dialog/amend-email-dialog.form-builder';
import {
  ApprobationExplanationDialogFormBuilder,
  CancelationExplanationDialogFormBuilder,
  DeclinationExplanationDialogFormBuilder,
  DisputeExplanationDialogComponent,
} from '../../components/disputes/components/explanation-dialog';
// tslint:disable-next-line: max-line-length
import { AmendExplanationDialogFormBuilder } from '../../components/disputes/components/explanation-dialog/amend-explanation-dialog.form-builder';
import { RelatedDisputesComponent } from '../../components/disputes/components/invoice-details/related-disputes/related-disputes.component';
import { MatchDialogComponent } from '../../components/disputes/components/match-dialog/match-dialog.component';
// tslint:disable-next-line: max-line-length
import { ProDownloadTemplateDialogComponent } from '../../components/disputes/components/pro-download-template-dialog/pro-download-template-dialog.component';
import { ReassignDialogComponent } from '../../components/disputes/components/reassign-dialog/reassign-dialog.component';
// tslint:disable-next-line: max-line-length
import { SelectEmployeeDialogComponent } from '../../components/disputes/components/select-employee-dialog/select-employee-dialog.component';
import { SelectProDialogComponent } from '../../components/disputes/components/select-pro-dialog/select-pro-dialog.component';
import { SuccessDisputeDialogComponent } from '../../components/disputes/components/success-dispute-dialog';
import {
  ActionBarButtonTypesCd,
  ApprobationAndCloseExplanationDialogFormLabelsEnum,
  ApprobationExplanationDialogFormLabelsEnum,
  ApprovalProcessFormLabels,
  CancelationEmailDialogFormLabelsEnum,
  CancelationEmailDialogFormNamesEnum,
  CancelationExplanationDialogFormLabelsEnum,
  CancelationExplanationDialogFormNamesEnum,
  CancelationExplanationDialogMaxLenghtsEnum,
  DeclinationEmailDialogFormLabelsEnum,
  DeclinationEmailDialogFormNamesEnum,
  DeclinationExplanationDialogFormLabelsEnum,
  DeclinationExplanationDialogFormNamesEnum,
  DeclinationExplanationDialogMaxLenghtsEnum,
  DialogResponse,
} from '../../enums';
import { ButtonColors } from '../../enums/dialogs/button-colors-enum';
import { AmendEmailDialogFormNamesEnum } from '../../enums/form-control-names/amend-email-dialog-form-names.enum';
import { AmendExplanationDialogFormNamesEnum } from '../../enums/form-control-names/amend-explanation-dialog-form-names.enum';
import { ApprobationEmailDialogFormNamesEnum } from '../../enums/form-control-names/approbation-email-dialog-form-names.enum';
import { ApprobationExplanationDialogFormNamesEnum } from '../../enums/form-control-names/approbation-explanation-dialog-form-names.enum';
import { AmendEmailDialogFormLabelsEnum } from '../../enums/form-labels/amend-email-dialog-form-labels.enum';
import { AmendExplanationDialogFormLabelsEnum } from '../../enums/form-labels/amend-explanation-dialog-form-labels.enum';
import { ApprobationEmailDialogFormLabelsEnum } from '../../enums/form-labels/approbation-email-dialog-form-labels.enum';
import { AmendExplanationDialogMaxLenghtsEnum } from '../../enums/form-max-lengths/amend-explanation-dialog-max-lengths.enum';
import { ApprobationExplanationDialogMaxLenghtsEnum } from '../../enums/form-max-lengths/approbation-explanation-dialog-max-lengths.enum';
import { DialogResponseData } from '../../interfaces/dialog-response-data.interface';
import { XpoDialogManagerService } from '../xpo-dialog-manager.service';
import { UploadProConfirmationDialogComponent } from 'src/app/components/disputes/components/upload-pro-confirmation-dialog/upload-pro-confirmation-dialog.component';

@Injectable()
export class DisputesDialogService {
  constructor(
    private dialog: MatDialog,
    private xpoDialogManagerService: XpoDialogManagerService,
    protected snackBar: XpoSnackBar
  ) {}

  showSelectProDialog(proNumber: string, proItems: ShpmtRecycledPro[]): Observable<any> {
    return this.dialog
      .open(SelectProDialogComponent, {
        data: {
          proNumber,
          proItems,
        },
        width: '700px',
        disableClose: true,
      })
      .afterClosed();
  }

  showDownloadProTemplateDialog(callback: Function): Observable<any> {
    return this.dialog
      .open(ProDownloadTemplateDialogComponent, {
        data: { callback },
        width: '700px',
        disableClose: true,
      })
      .afterClosed();
  }
  showDisputesExportDialogComponent(title: string, description: string): Observable<any> {
    return this.dialog
      .open(DisputesExportDialogComponent, {
        data: { title, description },
        width: '700px',
        disableClose: true,
      })
      .afterClosed();
  }

  showSelectEmployeeDialog(title: string): Observable<any> {
    return this.dialog
      .open(SelectEmployeeDialogComponent, {
        data: { title },
        width: '1500px',
        disableClose: true,
      })
      .afterClosed();
  }

  showAlertMessage(title: string, content: string) {
    return this.xpoDialogManagerService.alert({
      titleText: title,
      contentText: content,
    });
  }

  showSuccessDisputeCreated(
    title: string,
    message: string,
    status: string,
    statusMessage: string,
    confirmText: string,
    cancelText: string,
    additionalLinkText: string,
    additionalTextIcon: string,
    disputeId: string,
    additionalTextFunctionality
  ) {
    const dialogData = {
      title,
      message,
      status,
      statusMessage,
      confirmText,
      cancelText,
      additionalLinkText,
      additionalTextIcon,
      disputeId,
      additionalTextFunctionality,
    };
    return this.dialog
      .open(SuccessDisputeDialogComponent, {
        data: dialogData,
        width: '500px',
        disableClose: true,
      })
      .afterClosed();
  }

  showDisputeRouteGuardConfirmation(
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    isProImport?: boolean | undefined,
    buttonColor?: string,
    hideCloseDialogButton?: boolean
  ): Observable<DialogResponse> {
    const dialogData = {
      title: title,
      message: message,
      confirmText: confirmText,
      cancelText: cancelText,
      buttonColor: buttonColor || ButtonColors.PRIMARY,
      hideCloseDialogButton: hideCloseDialogButton,
    };
    return this.dialog
      .open(DisputeRouteGuardDialogComponent, {
        data: dialogData,
        width: isProImport ? '600px' : '500px',
        disableClose: true,
      })
      .afterClosed();
  }

  showDisputeSubmitConfirmation(
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    isProImport?: boolean | undefined,
    buttonColor?: string,
    hideCloseDialogButton?: boolean,
  ): Observable<DialogResponse> {
    const dialogData = {
      title: title,
      message: message,
      confirmText: confirmText,
      cancelText: cancelText,
      buttonColor: buttonColor || ButtonColors.PRIMARY,
      hideCloseDialogButton: hideCloseDialogButton,
    };
    return this.dialog
      .open(ConfirmNewDisputeDialogComponent, {
        data: dialogData,
        width: isProImport ? '600px' : '500px',
        disableClose: true,
      })
      .afterClosed();
  }

  showUploadProConfirmDialog(
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    isProImport?: boolean | undefined,
    buttonColor?: string,
    hideCloseDialogButton?: boolean,
    downloadConfirmText?: string,
  ): Observable<DialogResponse> {
    const dialogData = {
      title: title,
      message: message,
      confirmText: confirmText,
      cancelText: cancelText,
      buttonColor: buttonColor || ButtonColors.PRIMARY,
      hideCloseDialogButton: hideCloseDialogButton,
      downloadConfirmText: downloadConfirmText,
    };
    return this.dialog
      .open(UploadProConfirmationDialogComponent, {
        data: dialogData,
        width: isProImport ? '600px' : '400px',
        disableClose: true,
      })
      .afterClosed();
  }

  showClaimsDisputeSubmitConfirmation(
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
    buttonColor?: string,
    hideCloseDialogButton?: boolean
  ): Observable<DialogResponse> {
    const dialogData = {
      title: title,
      message,
      confirmText,
      cancelText,
      buttonColor: buttonColor || ButtonColors.PRIMARY,
      hideCloseDialogButton,
    };
    return this.dialog
      .open(ConfirmNewClaimsDisputeDialogComponent, {
        data: dialogData,
        width: '600px',
        disableClose: true,
      })
      .afterClosed();
  }

  showDisputeSnackBar(item: {
    message: string;
    detailedMessage?: string;
    onAction?: () => void;
    action?: string;
    status?: XpoSnackBarStatus;
    duration?: number;
  }) {
    this.snackBar.open({
      message: item.message,
      detailedMessage: item.detailedMessage,
      status: item.status ? item.status : 'success',
      matConfig: {
        duration: item.duration ? item.duration : 5000,
      },
    });
  }

  showMatchDialog(title: string, explanation: string, party: Party, onNoMatchCallback: Function) {
    return this.dialog
      .open(MatchDialogComponent, {
        data: {
          title,
          explanation,
          party,
          onNoMatchCallback,
        },
        width: '900px',
        disableClose: true,
      })
      .afterClosed();
  }

  private showExplanationDialog(action: ActionBarButtonTypesCd, buttonColor?: string): Observable<DialogResponse> {
    return this.dialog
      .open(DisputeExplanationDialogComponent, {
        data: {
          action,
          enums: this.getExplanationEnums(action),
          form: {
            formBuilder: this.getExplanationFormBuilder(action),
          },
          buttonColor: buttonColor,
        },
        width: '500px',
        disableClose: true,
      })
      .afterClosed();
  }

  showRelatedDisputesDialog(data) {
    return this.dialog.open(RelatedDisputesComponent, { width: '900px', data, disableClose: true }).afterClosed();
  }

  /**
   * @deprecated
   * This is not used for approval process.
   */
  showEmailDialog(action: ActionBarButtonTypesCd): Observable<DialogResponseData<EmailInteraction>> {
    return this.dialog
      .open(EmailDialogComponent, {
        width: '1000px',
        data: {
          enums: this.getEmailEnums(action),
          form: {
            formBuilder: this.getEmailFormBuilder(action),
          },
        },
        disableClose: true,
      })
      .afterClosed();
  }

  showContactsListDialog(partyId: number, cisCustNbr: string): Observable<Contact> {
    return this.dialog
      .open(ContactsListDialogComponent, {
        width: '700px',
        data: {
          partyId,
          cisCustNbr,
        },
        disableClose: true,
      })
      .afterClosed();
  }

  showReassignDialog(): Observable<DialogResponseData> {
    return this.dialog
      .open(ReassignDialogComponent, {
        width: '700px',
        disableClose: true,
      })
      .afterClosed();
  }

  showDisputeHistory(disputeId: string, itemId: number): Observable<DialogResponseData> {
    return this.dialog
      .open(DisputesHistoryDialogComponent, {
        width: '800px',
        disableClose: true,
        data: {
          disputeId,
          itemId,
        },
      })
      .afterClosed();
  }

  // #region Approve Dialogs

  showDisputesApproveConfirmation() {
    return this.showDisputeSubmitConfirmation(
      ApprovalProcessFormLabels.CONFIRM_APPROVE_TITLE,
      ApprovalProcessFormLabels.CONFIRM_APPROVE_DESCRIPTION,
      ApprovalProcessFormLabels.CONFIRM_APPROVE_OK_BUTTON,
      ApprovalProcessFormLabels.CONFIRM_APPROVE_GO_BACK_BUTTON
    );
  }

  showDisputesApproveExplanation() {
    return this.showExplanationDialog(ActionBarButtonTypesCd.APPROVE);
  }

  // #endregion

  // #region Amend Dialogs

  showDisputesAmendConfirmation() {
    return this.showDisputeSubmitConfirmation(
      ApprovalProcessFormLabels.CONFIRM_AMEND_TITLE,
      ApprovalProcessFormLabels.CONFIRM_AMEND_DESCRIPTION,
      ApprovalProcessFormLabels.CONFIRM_AMEND_OK_BUTTON,
      ApprovalProcessFormLabels.CONFIRM_AMEND_GO_BACK_BUTTON
    );
  }

  showDisputesAmendExplanation() {
    return this.showExplanationDialog(ActionBarButtonTypesCd.AMEND);
  }

  // #endregion

  // #region Decline Dialogs

  showDisputesDeclineConfirmation() {
    return this.showDisputeSubmitConfirmation(
      ApprovalProcessFormLabels.CONFIRM_DECLINATION_TITLE,
      ApprovalProcessFormLabels.CONFIRM_DECLINATION_DESCRIPTION,
      ApprovalProcessFormLabels.CONFIRM_DECLINATION_OK_BUTTON,
      ApprovalProcessFormLabels.CONFIRM_DECLINATION_GO_BACK_BUTTON,
      false,
      ButtonColors.WARN
    );
  }

  showDisputesDeclineExplanation() {
    return this.showExplanationDialog(ActionBarButtonTypesCd.DECLINE, ButtonColors.WARN);
  }

  // #endregion

  // #region Cancel Dialogs

  showDisputesCancelConfirmation() {
    return this.showDisputeSubmitConfirmation(
      ApprovalProcessFormLabels.CONFIRM_CANCELATION_TITLE,
      ApprovalProcessFormLabels.CONFIRM_CANCELATION_DESCRIPTION,
      ApprovalProcessFormLabels.CONFIRM_CANCELATION_OK_BUTTON,
      ApprovalProcessFormLabels.CONFIRM_CANCELATION_GO_BACK_BUTTON
    );
  }

  showDisputesCancelExplanation() {
    return this.showExplanationDialog(ActionBarButtonTypesCd.CANCEL);
  }

  // #endregion

  private getExplanationEnums(action: ActionBarButtonTypesCd) {
    switch (action) {
      case ActionBarButtonTypesCd.APPROVE:
        return {
          explanationDialogFormLabelsEnum: ApprobationExplanationDialogFormLabelsEnum,
          explanationDialogMaxLenghtsEnum: ApprobationExplanationDialogMaxLenghtsEnum,
          explanationDialogFormNamesEnum: ApprobationExplanationDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        return {
          explanationDialogFormLabelsEnum: ApprobationAndCloseExplanationDialogFormLabelsEnum,
          explanationDialogMaxLenghtsEnum: ApprobationExplanationDialogMaxLenghtsEnum,
          explanationDialogFormNamesEnum: ApprobationExplanationDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.DECLINE:
        return {
          explanationDialogFormLabelsEnum: DeclinationExplanationDialogFormLabelsEnum,
          explanationDialogMaxLenghtsEnum: DeclinationExplanationDialogMaxLenghtsEnum,
          explanationDialogFormNamesEnum: DeclinationExplanationDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.CANCEL:
        return {
          explanationDialogFormLabelsEnum: CancelationExplanationDialogFormLabelsEnum,
          explanationDialogMaxLenghtsEnum: CancelationExplanationDialogMaxLenghtsEnum,
          explanationDialogFormNamesEnum: CancelationExplanationDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.AMEND:
        return {
          explanationDialogFormLabelsEnum: AmendExplanationDialogFormLabelsEnum,
          explanationDialogMaxLenghtsEnum: AmendExplanationDialogMaxLenghtsEnum,
          explanationDialogFormNamesEnum: AmendExplanationDialogFormNamesEnum,
        };
    }
  }

  private getEmailEnums(action: ActionBarButtonTypesCd) {
    switch (action) {
      case ActionBarButtonTypesCd.APPROVE:
        return {
          emailDialogFormLabelsEnum: ApprobationEmailDialogFormLabelsEnum,
          emailDialogFormNamesEnum: ApprobationEmailDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        return {
          emailDialogFormLabelsEnum: ApprobationEmailDialogFormLabelsEnum,
          emailDialogFormNamesEnum: ApprobationEmailDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.DECLINE:
        return {
          emailDialogFormLabelsEnum: DeclinationEmailDialogFormLabelsEnum,
          emailDialogFormNamesEnum: DeclinationEmailDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.CANCEL:
        return {
          emailDialogFormLabelsEnum: CancelationEmailDialogFormLabelsEnum,
          emailDialogFormNamesEnum: CancelationEmailDialogFormNamesEnum,
        };
      case ActionBarButtonTypesCd.AMEND:
        return {
          emailDialogFormLabelsEnum: AmendEmailDialogFormLabelsEnum,
          emailDialogFormNamesEnum: AmendEmailDialogFormNamesEnum,
        };
    }
  }

  private getExplanationFormBuilder(action: ActionBarButtonTypesCd) {
    switch (action) {
      case ActionBarButtonTypesCd.APPROVE:
        return ApprobationExplanationDialogFormBuilder;
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        return ApprobationExplanationDialogFormBuilder;
      case ActionBarButtonTypesCd.DECLINE:
        return DeclinationExplanationDialogFormBuilder;
      case ActionBarButtonTypesCd.CANCEL:
        return CancelationExplanationDialogFormBuilder;
      case ActionBarButtonTypesCd.AMEND:
        return AmendExplanationDialogFormBuilder;
    }
  }

  private getEmailFormBuilder(action: ActionBarButtonTypesCd) {
    switch (action) {
      case ActionBarButtonTypesCd.APPROVE:
        return ApprobationEmailDialogFormBuilder;
      case ActionBarButtonTypesCd.APPROVE_AND_CLOSE:
        return ApprobationEmailDialogFormBuilder;
      case ActionBarButtonTypesCd.DECLINE:
        return DeclinationEmailDialogFormBuilder;
      case ActionBarButtonTypesCd.CANCEL:
        return CancelationEmailDialogFormBuilder;
      case ActionBarButtonTypesCd.AMEND:
        return AmendEmailDialogFormBuilder;
    }
  }
}
