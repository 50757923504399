<app-base-dispute-dialog
  [title]="title"
  (closeButtonClick)="handleCloseButtonClick()"
  [hideCloseDialogButton]="hideCloseDialogButton"
  [buttons]="confirmDisputeDialogButtons"
  (buttonClick)="handleClick($event)"
>
  <div class="confirmation-content">
      <div><mat-icon class="material-icons" aria-label="icon-button">info</mat-icon></div>
      <div><p class="confirmation-content--message" [innerHTML]="message"></p></div>
    <!-- <mat-icon class="material-icons" aria-label="icon-button">info</mat-icon> <p class="confirmation-content--message" [innerHTML]="message"></p>
    <div style="display: flex;">
    </div> -->

  </div>
</app-base-dispute-dialog>
