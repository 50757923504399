export enum DialogEventKeysEnum {
  GO_BACK = 'goBackEvent',
  SUBMIT = 'submitEvent',
  GOTO_NEW_DISPUTE = 'goToNewDisputeEvent',
  GOTO_DASOBOARD = 'goToDashboardEvent',
  DECLINE = 'declineEvent',
  SELECT = 'selectEvent',
  EXIT_THIS_PAGE = 'exitThisPageEvent',
  STAY_ON_THIS_PAGE = 'stayOnThisPageEvent',
  DOWNLOAD_CONFIRM = 'downloadConfirmation'
}
