import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';

@Pipe({
  name: 'requestReason',
})
export class RequestReasonPipe implements PipeTransform {
  transform(reason: string) {
    if (typeof reason !== 'string' || reason === '') {
      return reason;
    }

    let cleanedReason = reason.replace('OCC', '');
    cleanedReason = cleanedReason.replace('WNI', '');
    cleanedReason = cleanedReason.replace('DRP', '');
    cleanedReason = startCase(cleanedReason);
    
    return this.cleanRequestReasonLabel(cleanedReason);
  }

  private cleanRequestReasonLabel(value: String): String {
    value = value.replace('X P O', 'XPO');
    value = value.replace('Or', '/');
    value = value.replace('W I', 'WI');
    value = value.replace('Notan', 'Not an');
    value = value.replace('Statuteof', 'Statute of');
    return value;
  }
}
