import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ButtonType, DialogEventKeysEnum, DialogResponse } from 'src/app/enums';
import { BaseDisputeDialogButton } from 'src/app/interfaces/base-dispute-dialog-button.interface';
import { ConfirmNewDisputeDialogComponent } from '../confirm-new-dispute-dialog';

@Component({
  selector: 'app-upload-pro-confirmation-dialog',
  templateUrl: './upload-pro-confirmation-dialog.component.html',
  styleUrls: ['./upload-pro-confirmation-dialog.component.scss']
})
export class UploadProConfirmationDialogComponent {
  private _title: string;
  private _message: string;
  private _confirmText: string;
  private _cancelText: string;
  private _buttonColor: string;
  private _hideCloseDialogButton: boolean;
  private _downloadConfirmText: string;

  confirmDisputeDialogButtons: BaseDisputeDialogButton[];

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<ConfirmNewDisputeDialogComponent>
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;
    this._confirmText = dialogData.confirmText;
    this._cancelText = dialogData.cancelText;
    this._buttonColor = dialogData.buttonColor;
    this._hideCloseDialogButton = dialogData.hideCloseDialogButton;
    this._downloadConfirmText = dialogData.downloadConfirmText;
  }

  ngOnInit() {
    this.confirmDisputeDialogButtons = [
      {
        displayText: this._cancelText,
        buttonType: ButtonType.STROKED,
        eventKey: DialogEventKeysEnum.GO_BACK,
        color: this._buttonColor,
      },
      {
        displayText: this._downloadConfirmText,
        buttonType: ButtonType.STROKED,
        eventKey: DialogEventKeysEnum.DOWNLOAD_CONFIRM,
        color: this._buttonColor,
      },
      {
        displayText: this._confirmText,
        buttonType: ButtonType.FLAT,
        eventKey: DialogEventKeysEnum.SUBMIT,
        color: this._buttonColor,
      },
    ];
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get hideCloseDialogButton(): boolean {
    return this._hideCloseDialogButton;
  }

  handleCloseButtonClick() {
    this.dialogRef.close(DialogResponse.DIALOG_CLOSED);
  }

  handleClick(eventKey: DialogEventKeysEnum) {
    switch (eventKey) {
      case DialogEventKeysEnum.GO_BACK:
        this.handleGoBackClicked();
        break;
      case DialogEventKeysEnum.SUBMIT:
        this.handleSubmitClicked();
        break;
      case DialogEventKeysEnum.DOWNLOAD_CONFIRM:
        this.handleDownloadConfirmClicked();
        break;
    }
  }

  private handleGoBackClicked() {
    this.dialogRef.close(DialogResponse.GO_BACK);
  }

  private handleSubmitClicked() {
    this.dialogRef.close(DialogResponse.SUBMIT);
  }

  private handleDownloadConfirmClicked() {
    this.dialogRef.close(DialogResponse.DOWNLOAD_CONFIRM);
  }
}
